import { debounce } from 'lodash-es';
import { loadOverlay } from "../util/overlays";

/**
 * KioskOverlay
 *  - Handles inactivity overlay for kiosk mode
 *
 * @uthor Lawrence Cherone <lawrence@d3r.com>
 */
export default class KioskOverlay {

    /* @var Bool */
    overlayOpen = false;

    /* @var Number  */
    timeout = 0;

    /* @var Number */
    timer = null;

    /**
     * @param {HTMLElement} elm
     * @param {Object} options
     */
    constructor() {
        const timeout = this.getTimeoutValue();
        if (timeout) {
            this.timeout = timeout;
            this.init();
        }
    }

    /**
     * Get cookie by name
     */
    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            const data = decodeURIComponent(parts.pop().split(';').shift());
            try {
                return JSON.parse(data);
            } catch {}
        }

        return null;
    }

    /**
     * Get timeout value from cookie
     */
    getTimeoutValue() {
        const value = this.getCookie('kiosk');

        if (value && typeof value[0] !== 'undefined' && !isNaN(value[0])) {
            return parseInt(value[0]) * 1000;
        }

        return null;
    }

    /**
     * Check if ?kiosk-overlay=1 is set in query string, if so show overlay
     *  - Also remove from history
     */
    init() {
        this.addEventListeners();
        this.timerReset();

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const kioskOverlay = urlParams.get('kiosk-overlay');
        if (kioskOverlay) {
            this.show();
            history.replaceState(null, '', '/');
        }
    }

    /**
     * Show the overlay
     */
    show() {
        loadOverlay('/kiosk/overlay', {
            modifiers: ['takeover', 'inactivity'],
            script_callback: (e) => {
                // If overlay is not already open and loaded script is kiosk.js, emit event to init kiosk content
                if (e.includes('kiosk') && e.includes('.js') && !this.overlayOpen) {
                    window.dispatchEvent(new Event('overlay:show'));
                    this.addOverlayEventListeners();
                    this.overlayOpen = true;
                }
            }
        });
    }

    /**
     * Hide the overlay
     *  - Clear timeout
     *  - Close overlay
     *  - Set flag in local storage to trigger different action
     */
    hide() {
        window.dispatchEvent(new Event('overlay:hide'));

        clearTimeout(this.timer);
        Overlay.close();
        localStorage['kiosk-session'] = 1;
        this.overlayOpen = false;
    }

    /**
     * Clear down the session
     *  - If session exists, locate
     *  - Otherwise show overlay
     */
    cleardown() {
        if (localStorage['kiosk-session']) {
            delete localStorage['kiosk-session'];
            window.location = '/kiosk/cleardown';
        } else if (!this.overlayOpen) {
            this.show();
        }
    }

    /**
     * Debounced timer method
     */
    timerReset = debounce(() => {
        clearTimeout(this.timer);
        this.timer = setTimeout(this.cleardown.bind(this), this.timeout);
    }, 1000);

    /**
     * Add event listeners to body/overlay
     */
    addEventListeners() {
        const events = ['click', 'touchstart', 'touchmove', 'wheel', 'pointerdown', 'pointermove', 'scroll', 'keydown', 'mousemove'];
        events.forEach(event => document.body.addEventListener(event, () => {
            this.timerReset();
        }));
    }

    /**
     * Add overlay event listeners
     */
    addOverlayEventListeners() {
        const events = ['click', 'touchstart', 'touchmove', 'wheel', 'pointerdown', 'pointermove', 'scroll', 'keydown', 'mousemove'];
        events.forEach(event => Overlay.target.querySelector('.overlay__content').addEventListener(event, () => {
            this.hide();
        }));
    }
}
